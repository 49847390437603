// Angular 
import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// Rxjs
import { ReplaySubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadScriptService {
    private readonly document = inject<Document>(DOCUMENT);


    // Public
    loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

    /**
     * Lazyload the script
     * @param {string} url
     * @param {string} [id]
     * @returns {Observable<void>}
     * @memberof LazyLoadScriptService
     */
    loadScript(url: string, id?: string): Observable<void> {
        if (this.loadedLibraries[url]) {
            return this.loadedLibraries[url].asObservable();
        }

        // Check if script is already loaded
        const scriptElements = this.document.querySelectorAll(`script[src="${url}"]`);
        if (scriptElements.length > 0) {
            this.loadedLibraries[url] = new ReplaySubject();
            this.loadedLibraries[url].next();
            this.loadedLibraries[url].complete();
            return this.loadedLibraries[url].asObservable();
        }

        this.loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.defer = true;
        if (id) script.id = id;
        script.src = url;
        script.onload = () => {
            this.loadedLibraries[url].next();
            this.loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);

        return this.loadedLibraries[url].asObservable();
    }
}