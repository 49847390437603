// Angular
import { Injectable, PLATFORM_ID, inject, provideAppInitializer } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
// RxJS
import { catchError, tap, timeout } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
// Constant
import { API_URL } from '@constants';
import { environment } from '@environment';

@Injectable({
    providedIn: 'root',
})
export class GeoIPService {
    private readonly http = inject(HttpClient);
    private platformId = inject<Object>(PLATFORM_ID);


    /** Varibale Declaration */
    geoIPResponse: any;

    /**
     * Get user geo ip data
     * @return {*}  {Observable<any>}
     * @memberof GeoIPService
     */
    public init(): Observable<any> {
        if (isPlatformBrowser(this.platformId)) {
            return this.http
                .get(environment.apiBaseUrl + API_URL.GEO_IP_URL)
                .pipe(
                    tap((response: any) => {
                        if (response.total > 0 && response.success) {
                            this.geoIPResponse = response.data;
                        }
                    }),
                    timeout(1500),
                    catchError((error) => {
                        return of([]);
                    })
                );
        } else {
            return of([]);
        }
    }
}

/**
 * APP Initilize Factory Service
 * @param {GeoIPService} geoIP
 * @return {*}
 */
function GeoIpInitFactoryService(geoIP: GeoIPService) {
    return () => geoIP.init();
}

export const GEO_IP_INIT_PROVIDER = provideAppInitializer(() => {
        const initializerFn = (GeoIpInitFactoryService)(inject(GeoIPService));
        return initializerFn();
      });