// Angular
import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
// RxJS
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
// NGRX
import { Store } from '@ngrx/store';
// Auth Store
import { AppState, Logout } from './auth.store';
// Services
import { GlobalErrorHandler, CommonService, SentryService } from '@core/utils';
import { TranslocoService } from '@jsverse/transloco';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {

  // Inject services
  const store = inject<Store<AppState>>(Store);
  const router = inject(Router);
  const authService = inject(AuthService);
  const globalErrorHandler = inject(GlobalErrorHandler);
  const cs = inject(CommonService);
  const sentryService = inject(SentryService);
  const translocoService = inject(TranslocoService);
  const platformId = inject<Object>(PLATFORM_ID);

  const unsubscribe = new Subject<void>();

  return authService.getAuthToken().pipe(
    map((response) => {
      if (!response) handleUnauthorizedAccess(store, cs, sentryService, translocoService, router, state, platformId);
      return !!response;
    }),
    catchError(error => {
      handleUnauthorizedAccess(store, cs, sentryService, translocoService, router, state, platformId);
      return globalErrorHandler.handleError('auth-guard', error, true);
    }),
    takeUntil(unsubscribe)
  );
};

/**
 * Handle un-authorized access
 * @returns Void
 */
const handleUnauthorizedAccess = (
  store: Store<AppState>,
  cs: CommonService,
  sentryService: SentryService,
  translocoService: TranslocoService,
  router: Router,
  state: RouterStateSnapshot,
  platformId: Object
): void => {
  
  try {
    if (isPlatformBrowser(platformId)) {
      store.dispatch(new Logout());
      cs.clearAll();
      sentryService.resetAll();
      const returnUrl = state.url !== '/' ? state.url : undefined;
      const lang = translocoService.getActiveLang();
      router.navigate([`/${lang}/auth/login`], {
        queryParams: { returnUrl },
      });
    }
  } catch (error) {
    inject(GlobalErrorHandler).handleError('auth-guard-handle-unauthorized-access', error);
  }
};
