// Angular
import { inject } from '@angular/core';
import { HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { Observable } from 'rxjs';
// Constants
import { APP_CONSTANT } from '@constants';
// Service
import { CommonService } from './common.service';

export const TokenInterceptor: HttpInterceptorFn = (req, next): Observable<HttpEvent<any>> => {
  const cs = inject(CommonService);
  const ssrHttpRequest = inject<any>('REQUEST' as any, { optional: true });

  // Extract client IP address
  const CLIENT_IP_ADDRESS =
    ssrHttpRequest?.headers['x-real-ip'] ||
    ssrHttpRequest?.headers['x-forwarded-for'] ||
    ssrHttpRequest?.connection?.remoteAddress ||
    ssrHttpRequest?.socket?.remoteAddress;

  let modifiedRequest = req;

  if (CLIENT_IP_ADDRESS) {
    modifiedRequest = modifiedRequest.clone({
      headers: modifiedRequest.headers.set('x-forwarded-for', CLIENT_IP_ADDRESS)
    });
  }

  // Add JWT token to request
  const jwt = cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
  if (jwt) {
    modifiedRequest = modifiedRequest.clone({
      headers: modifiedRequest.headers.set('Authorization', `Bearer ${jwt}`)
    });
  }

  return next(modifiedRequest);
};