import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, of, Subscription } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store, ActionReducerMap, MetaReducer, createSelector } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { get } from 'lodash';
import { SentryService, CommonService } from '@core/utils';
import { ISentryUserIdentity } from '@shared/models';
import { APP_CONSTANT } from '@constants';
import { TranslocoService } from '@jsverse/transloco';
import { IUserAccount } from './user.model';
import { AuthService } from './auth.service';

// Auth Actions Enum
export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    ErrorOccured = '[Login] Auth API'
}

// Auth Actions
export class Login {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { user: IUserAccount, authToken: string }) { }
}

export class Logout {
    readonly type = AuthActionTypes.Logout;
}

export class UserRequested {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: IUserAccount }) { }
}

export class ErrorOccured {
    readonly type = AuthActionTypes.ErrorOccured;
    constructor(public payload: { error: any }) { }
}

export type AuthActions = Login | Logout | UserRequested | UserLoaded | ErrorOccured;

// Auth State
export interface AuthState {
    loggedIn: boolean;
    authToken: string | undefined;
    user: IUserAccount | undefined;
    isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false
};

// Auth Reducer
export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.Login:
            return { ...state, loggedIn: true, authToken: action.payload.authToken, isUserLoaded: false };
        case AuthActionTypes.Logout:
            return initialAuthState;
        case AuthActionTypes.UserLoaded:
            return { ...state, user: action.payload.user, isUserLoaded: true, loggedIn: true };
        default:
            return state;
    }
}

// Selectors
export const selectAuthState = (state: any) => state.auth;
export const isLoggedIn = createSelector(selectAuthState, auth => Boolean(auth.loggedIn));
export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);
export const currentAuthToken = createSelector(selectAuthState, auth => auth.authToken);
export const isUserLoaded = createSelector(selectAuthState, auth => auth.isUserLoaded);
export const currentUser = createSelector(selectAuthState, auth => auth.user);
export const userEnvironment = createSelector(selectAuthState, auth => auth.user?.userenvironment);

// App State
export interface AppState { }
export const reducers: ActionReducerMap<AppState> = { router: routerReducer };
export const metaReducers: MetaReducer<AppState>[] = [];

// Auth Effects
@Injectable({ providedIn: 'root' })
export class AuthEffects implements OnDestroy {
    private actions$ = inject(Actions);
    private router = inject(Router);
    private auth = inject(AuthService);
    private cs = inject(CommonService);
    private sentryService = inject(SentryService);
    private store = inject<Store<AppState>>(Store);
    private translocoService = inject(TranslocoService);
    private returnUrl: string | null;
    private unsubscribe: Subscription[] = [];

    constructor() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.returnUrl = event.urlAfterRedirects === APP_CONSTANT.ERROR_PATH ? null : event.url;
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    login$ = createEffect(() => this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap((action: Login) => {
            this.cs.setIntoLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY, action.payload.authToken);
            this.cs.setIntoLocalStorage(APP_CONSTANT.LOGGED_USER, action.payload.user);
            const localeId = this.cs.getUserLocale(get(action.payload.user, 'environment.language', '840')).locale;
            this.cs.setIntoLocalStorage(APP_CONSTANT.USER_LOCALE_KEY, localeId);
            this.translocoService.setDefaultLang(localeId);
            this.translocoService.setActiveLang(localeId);
            this.store.dispatch(new UserRequested());
        })
    ), { dispatch: false });

    logout$ = createEffect(() => this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            this.cs.clearAll();
            this.sentryService.resetAll();
            this.router.navigate([`${this.translocoService.getActiveLang()}/auth/login`], { queryParams: { returnUrl: this.returnUrl } });
        })
    ), { dispatch: false });

    loadUser$ = createEffect(() => this.actions$.pipe(
        ofType<UserRequested>(AuthActionTypes.UserRequested),
        withLatestFrom(this.store.pipe(select(isUserLoaded))),
        filter(([_, checkUserLoaded]) => !checkUserLoaded),
        mergeMap(() => this.auth.getAccountProfile()),
        tap((result) => {
            if (result) {
                const sentryUserIdentity: ISentryUserIdentity = {
                    id: get(result, 'user.id'),
                    username: get(result, 'user.name'),
                    email: get(result, 'user.email'),
                    companyname: get(result, 'company.name', '')
                };
                this.sentryService.setUser(sentryUserIdentity);
                this.store.dispatch(new UserLoaded({ user: result }));
            } else {
                this.store.dispatch(new Logout());
            }
        })
    ), { dispatch: false });

    init$ = createEffect(() => defer(() => {
        const authToken = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
        if (authToken) {
            this.store.dispatch(new UserRequested());
        }
        return of({ type: 'NO_ACTION' });
    }));

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
