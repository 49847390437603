// Angular
import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth store
import { AppState, isLoggedIn } from './auth.store';
// Services
import { GlobalErrorHandler } from '@core/utils';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

export const LoggedInAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {

    // Inject services
    const store = inject<Store<AppState>>(Store);
    const router = inject(Router);
    const globalErrorHandler = inject(GlobalErrorHandler);
    const translocoService = inject(TranslocoService);

    return store.pipe(
        select(isLoggedIn),
        map((loggedIn) => {
            if (loggedIn) {
                router.navigateByUrl(`/${translocoService.getActiveLang()}`);
            }
            return !loggedIn;
        }),
        catchError((error) => globalErrorHandler.handleError('logged-in-auth-guard', error))
    );
};
