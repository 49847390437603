// Angular
import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
import { environment, seoImageUrl } from '@environment';
// Lodash
import { forEach, get, keys } from 'lodash';

// Default SEO Meta Data
const SEO: any = {
    ...environment.seo,
    hrefLangs: {
        208: ['da-DK', 'Danska', '/da-dk'],
        246: ['fi-FI', 'Finska', '/fi-fi'],
        250: ['fr-FR', 'Franska', '/fr-fr'],
        276: ['de-DE', 'Tyska', '/de-de'],
        352: ['is-IS', 'Islänska', '/is-is'],
        380: ['it-IT', 'Italienska', '/it-it'],
        578: ['nb-NO', 'Norska', '/nb-no'],
        724: ['es-ES', 'Spanska', '/es-es'],
        752: ['sv-SE', 'Svenska', '/sv-se'],
        840: ['en-SE', 'Engelska', '/en-se'],
        0: ['en-SE', 'Engelska', '/en-se']
    },
};

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private title = inject(Title);
    private meta = inject(Meta);
    private translocoService = inject(TranslocoService);
    private document = inject<Document>(DOCUMENT);


    // Private
    private _canonicalLink!: HTMLLinkElement;
    private _alternateLinks: HTMLLinkElement[] = [];

    /**
     * Creates an instance of SeoService.
     * @param {Title} title
     * @param {Meta} meta
     * @param {TranslocoService} translocoService
     * @param {Document} document
     * @memberof SeoService
     */
    constructor() {
        // Add default meta tags
        this.setDefaultMetaTags();
    }

    /**
     * Set Default Meta Tags
     * @private
     * @memberof SeoService
     */
    private setDefaultMetaTags() {
        // Add tags
        this.meta.addTags(SEO.tags as MetaDefinition[]);
        const description = this.getTitleAndDesc().description;
        this.setDescription(description);
        // Add canonical link
        const _canonical = this.document.querySelector('link[rel="canonical"]');
        this._canonicalLink = (_canonical as HTMLLinkElement) || this.createCanonicalLink();

        // Add alternate language, one at a time, here
        const _links = this.document.querySelectorAll('link[rel="alternate"]');
        if (_links.length > 0) {
            this._alternateLinks = Array.from(_links) as HTMLLinkElement[];
        } else {
            this._alternateLinks = keys(SEO.hrefLangs).map((n) =>
                this.createAlternateLink()
            );
        }
    }

    /**
     * Create Alternate Link
     * @private
     * @returns {HTMLLinkElement}
     * @memberof SeoService
     */
    private createAlternateLink(): HTMLLinkElement {
        // append alternate link to body
        const _link = this.document.createElement('link');
        _link.setAttribute('rel', 'alternate');
        this.document.head.appendChild(_link);
        return _link;
    }

    /**
     * Create Canonical Link
     * @private
     * @returns {HTMLLinkElement}
     * @memberof SeoService
     */
    private createCanonicalLink(): HTMLLinkElement {
        // append canonical to body
        const _canonicalLink = this.document.createElement('link');
        _canonicalLink.setAttribute('rel', 'canonical');
        this.document.head.appendChild(_canonicalLink);

        return _canonicalLink;
    }

    /**
     * Set Canonical Link
     * @param {string} [url]
     * @memberof SeoService
     */
    public setCanonicalLink(url?: string) {
        let href = url ? environment.baseUrl + url : environment.baseUrl + this.document.location.pathname;
        this._canonicalLink.setAttribute('href', href);
        this.meta.updateTag({ property: 'og:url', content: href });
    }

    /**
     * Set Robots Tag
     * @param {boolean} isAllowed
     * @memberof SeoService
     */
    public setRobotsTag(isAllowed: boolean) {
        const content = isAllowed ? 'follow,index,notranslate' : 'nofollow,noindex,notranslate';
        this.meta.updateTag({ name: 'robots', content });
    }

    /**
     * Set og:type tag as Product or Website as per pages
     * @param {('product' | 'website')} type
     * @memberof SeoService
     */
    public setSiteType(type: 'product' | 'website') {
        this.meta.updateTag({ property: 'og:type', content: type });
    }

    /**
     * Set Alternate Links
     * @param {*} [hrefLangData]
     * @memberof SeoService
     */
    public setAlternateLinks(hrefLangData?: any) {
        if (hrefLangData) {
            hrefLangData[0] = hrefLangData[840] ? hrefLangData[840] : hrefLangData[752];
            this.prepareAlternateLinks(hrefLangData);
        } else {
            this.prepareAlternateLinks(SEO.hrefLangs);
        }
    }

    /**
     * Prepare Alternate Links
     * @private
     * @param {*} hrefLangs
     * @memberof SeoService
     */
    private prepareAlternateLinks(hrefLangs: any) {
        let i = 0;
        forEach(hrefLangs, ((value, key) => {
            const url = `${environment.baseUrl}${value[2]}`;
            let hreflang = value[0];
            if (key === '0') { hreflang = 'x-default'; }
            this._alternateLinks[i].setAttribute('href', url);
            this._alternateLinks[i].setAttribute('hreflang', hreflang);
            i++;
        }));
    }

    /**
     * Set Title
     * @param {string} _title
     * @memberof SeoService
     */
    public setTitle(_title: string) {
        this.title.setTitle(_title);
        this.meta.updateTag({
            name: 'title',
            property: 'og:title',
            content: _title,
        });
    }

    /**
     * Set Description
     * @param {string} description
     * @memberof SeoService
     */
    public setDescription(description: string) {
        this.meta.updateTag({
            name: 'description',
            property: 'og:description',
            content: description,
        });
    }

    /**
     * Set Image
     * @param {string} [imageUrl]
     * @memberof SeoService
     */
    public setImage(imageUrl?: string) {
        // Prepare image, either passed or
        const _imageUrl = imageUrl || seoImageUrl;

        this.meta.updateTag({
            property: 'og:image',
            content: _imageUrl,
        });
    }

    /**
     * Set Page Title
     * @description Set to title if found, else fall back to default
     * @param {string} title
     * @memberof SeoService
     */
    public setPage(title: string) {

        // Set Locale to html tag ex: lang="en-se"
        this.document.documentElement.lang = this.translocoService.getActiveLang();

        if (title && null !== title) {
            this.translocoService.selectTranslate(title).subscribe((value) => this.setTitle(value));
        } else {
            this.setTitle(this.getTitleAndDesc().defaultTitle);
        }

        // Reset canonicals
        this.setCanonicalLink();
    }

    /**
     * Set Meta Data
     * @param {*} data
     * @param {boolean} [isV2API] : When using new api ie. /django/partsearch/
     * @memberof SeoService
     */
    public setMetaData(data: any) {
        if (data) {
            // set title
            this.setTitle(get(data, 'seo.html.title'));
            // update robots
            const result = get(data, 'result', []);
            const isAllowed: boolean = null !== result ? result.length > 0 ? true : false : false;
            this.setRobotsTag(isAllowed);
            // set description
            this.setDescription(get(data, 'seo.html.content'));
            // set canonical
            this.setCanonicalLink(get(data, 'seo.html.canonical'));
            // set alternate link
            this.setAlternateLinks(get(data, 'seo.html.hreflang'));
        } else {
            // When no result Set SEO Robots meta-data to noindex and nofollow
            this.setRobotsTag(false);
        }
    }

    /**
     * Get Page default title and description data from environment seo config
     * @private
     * @returns
     * @memberof SeoService
     */
    private getTitleAndDesc() {
        const lang = this.translocoService.getActiveLang();
        if (SEO[lang]) {
            return {
                defaultTitle: SEO[lang]?.title,
                description: SEO[lang]?.description,
            }
        } else {
            return {
                defaultTitle: SEO[SEO.fallback]?.title,
                description: SEO[SEO.fallback]?.description,
            }
        }
    }

}
