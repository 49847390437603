// Angular
import { Injectable, inject } from '@angular/core';
// RxJS
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Model
import { IUserLogin, User } from './user.model';
// Constants
import { APP_CONSTANT, API_URL } from '@constants';
// Service
import { HttpUtilsService, CommonService, GlobalErrorHandler } from '@core/utils';
// common header
const HEADER_ACCEPT_JSON = { 'accept': 'application/json' };

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly httpUtilsService = inject(HttpUtilsService);
  private readonly cs = inject(CommonService);
  private readonly globalErrorHandler = inject(GlobalErrorHandler);


  /**
   * Login request
   * @param {(IUserLogin)} loginData
   * @param {*} [headers]
   * @returns {Observable<User>}
   * @memberof AuthService
   */
  public login(loginData: IUserLogin, headers?: any): Observable<User> {
    return this.httpUtilsService
      .postData<User>(API_URL.LOGIN_URL, loginData, null, headers)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError(`user-login | ${loginData.username}`, error, true))
      );
  }

  /*
   * Get Auth token from localstorage
   * @returns {Observable<boolean>}
   */
  public getAuthToken(): Observable<boolean> {
    const authToken = this.cs.getFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
    let observableResult = of(false);
    if (authToken) {
      observableResult = of(true);
    }
    return observableResult;
  }

  /**
   * Get Profile Info
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public getAccountProfile(): Observable<any> {
    return this.httpUtilsService
      .getData(API_URL.ACCOUNT_PROFILE_URL, null, null)
      .pipe(
        map((response: any) => response.success ? response.data : null),
        catchError((error: any) => this.globalErrorHandler.handleError('fetch-account-profile', error, true))
      );
  }

  /*
   * Submit forgot password request
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.httpUtilsService
      .postData(API_URL.RESET_PWD_URL, { email }, null, HEADER_ACCEPT_JSON)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError(`request-password | ${email}`, error, true))
      );
  }

  /**
   * Change Account Password
   * @param payload: any
   * @returns Observable<any>
   */
  public changePassword(payload: any): Observable<any> {
    return this.httpUtilsService
      .postData(API_URL.RECOVER_PWD_URL, payload, null, HEADER_ACCEPT_JSON)
      .pipe(
        catchError(error => this.globalErrorHandler.handleError('change-password', error, true))
      );
  }
}
