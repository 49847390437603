// Angular
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Lodash & RxJS
import { capitalize, replace } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
// Constants
import { environment } from "@environment";
import { LOCALHOST_URL } from "@constants";
// Transloco
import { TranslocoService } from "@jsverse/transloco";

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    private readonly http = inject(HttpClient);
    private readonly translocoService = inject(TranslocoService);


    /**
     * Get Document
     * @returns {Observable<any>}
     * @memberof DocumentsService
     */
    public getDocument(page: string): Observable<any> {
        const title = capitalize(replace(page, /\-/g, ' '));
        const lang = this.translocoService.getActiveLang();
        const pageURL: string = `/assets/documents/${environment.orgId}/${lang}/${page}.html`;
        const documentURL: string = environment.production ? `${environment.baseUrl}${pageURL}` : LOCALHOST_URL+pageURL;
        return this.http.get(documentURL, { responseType: 'text' }).pipe(
            take(1),
            map((response) => {
                return { title, response }
            }),
            catchError((error) => {
                return of(null);
            })
        );
    }

}