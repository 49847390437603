export const API_URL = {
    LOGIN_URL: '/api/django/v2/account/login/authenticate',
    RESET_PWD_URL: '/api/django/v2/account/password/reset',
    RECOVER_PWD_URL: '/api/django/v2/account/password/recover',
    RESET_PWD_TOKEN_VERIFY_URL: '/api/django/v2/account/token/verify',
    LOGIN_USER_COMPANY_URL: '/rest/account/rpc/rsp_login_user_email',
    ACCOUNT_PROFILE_URL: '/api/django/v2/account/profile',
    UPDATE_PROFILE_URL: '/api/django/v2/account/user/info',
    UPDATE_PROFILE_ENV_URL: '/api/django/v2/account/user/environment',
    UPDATE_COMPANY_ADDRESS_URL: '/api/django/v2/account/company/address',
    UPDATE_USER_ADDRESS_URL: '/api/django/v2/account/user/address',
    UPDATE_USER_PHONE_URL:'/api/django/v2/account/user/phone',
    MAKES_LIST_URL: '/api/django/v2/search/part_tree_make',
    MODELS_LIST_URL: '/api/django/v2/search/part_tree_model',
    CATEGORY_LIST_URL: '/api/django/v2/search/part_tree_category',
    CATEGORY_PRODUCTS_URL: '/api/django/v2/search/part_tree_product',
    REG_NR_SEARCH_URL: '/api/django/v2/search/plate_number',
    AFFILIATED_COMPANIY_URL: '/api/django/v2/account/company/search/subject',
    COMPANY_FILTER_LIST: '/api/django/v2/account/company/search/filter',
    COMPANY_DETAIL_URL: '/rest/account/rpc/rsp_company_item',
    COMPANY_REVIEW_URL: '/api/django/v2/account/company/review',
    COUNTRY_COUNTY_URL: '/rest/type/rpc/rsp_county',
    INQUIRIES_LIST_URL: '/rest/book/view_cart_inquiry_customer',
    ORDER_TREE_URL: '/rest/order/rpc/rsp_tree_customer',
    ORDER_SUBJECT_URL: '/rest/order/view_order_',
    ORDER_SEND_MSG_URL: '/rest/order/rpc/set_message',
    ORDER_ACTION_URL: '/rest/order/rpc/action',
    LOGISTIC_COUNTRY_URL: '/api/django/v2/register/logistic/country',
    PRODUCTS_LIST_URL: '/api/django/v2/search/part_subject',
    FILTERS_LIST_URL: '/api/django/v2/search/part_filter',
    DF_PRODUCTS_LIST_URL: '/api/django/v2/search/tyre_rim_subject',
    DF_FILTERS_LIST_URL: '/api/django/v2/search/tyre_rim_filter',
    GEO_IP_URL: '/api/django/v2/register/geo/ip?provider=ip2location',
    FIND_CART_BY_EMAIL_OR_ID_URL: '/api/django/v2/cart/tool/search',
    FMS_USERS_URL: '/fms/list',
    CART_V2_URL: '/rest/book/rpc/cart',
    ACTIVE_CART_CALL_SESSION_URL: '/rest/account/session',
    CART_FBT_WS_URL: 'wss://cart.fbt.se',
    CART_V1_URL: 'https://api.bildelsbasen.se/public/developer/cart/',
    CONTACT_US_TICKET_URL: '/api/django/v2/contact/support/ticket',
    ORDER_SURVEY_URL: '/api/django/v2/intranet/order/survey',
    USER_ACCOUNT_DEACTIVATE_URL: '/api/django/v2/account/user/unregister',
    VS_MAKES_URL: '/api/django/v2/search/vehicle_tree_make',
    VS_MODELS_URL: '/api/django/v2/search/vehicle_tree_model',
    VS_SUBJECT_URL: '/api/django/v2/search/vehicle_subject',
    CREATE_CART_URL: '/api/django/v2/cart/orders/',
    ORDER_ITEM_DETAILS_URL: '/api/django/v2/intranet/order_item_part_log/',
    ORDER_SEND_INVOICE_URL: '/api/django/v2/purchase/api/orders/{order_id}/send_invoice',
    STATS_LOG_URL: '/api/django/v2/search/statistic'
};
