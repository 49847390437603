// Angular
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, signal, effect, inject } from '@angular/core';

// Theme modes
export type ThemeModeType = 'dark' | 'light' | 'system';

// Theme mode key for localstorege
const THEME_MODE_LS_KEY = 'BDB_theme_mode_value';

/**
 * Get theme mode from local storage
 * @param {string} lsKey
 * @param {Object} platformId
 * @returns {ThemeModeType}
 */
const getThemeModeFromLocalStorage = (lsKey: string, platformId: Object): ThemeModeType => {
  if (isPlatformBrowser(platformId)) {
    if (!localStorage) {
      return 'light';
    }

    const data = localStorage.getItem(lsKey);
    if (!data) {
      return 'light';
    }

    if (data === 'light') {
      return 'light';
    }

    if (data === 'dark') {
      return 'dark';
    }
  }
  return 'system';
};

@Injectable({
  providedIn: 'root',
})
export class ThemeModeService {

  // Private
  private platformId = inject<Object>(PLATFORM_ID);
  private document = inject<Document>(DOCUMENT);


  systemMode: ThemeModeType;
  mode = signal<ThemeModeType>(getThemeModeFromLocalStorage(THEME_MODE_LS_KEY, this.platformId));

  /**
   * Creates an instance of ThemeModeService.
   * @param {Object} platformId
   * @param {Document} document
   * @memberof ThemeModeService
   */
  constructor() {

    this.systemMode = this.getSystemMode() as 'light' | 'dark';

    effect(() => {
      const currentMode = this.mode();
      const updatedMode = currentMode === 'system' ? this.systemMode : currentMode;
      this.document.documentElement.setAttribute('data-theme', updatedMode);
      if (isPlatformBrowser(this.platformId) && localStorage) {
        localStorage.setItem(THEME_MODE_LS_KEY, updatedMode);
      }
    });
  }

  /**
   * Get system mode
   * @memberof ThemeModeService
   */
  public getSystemMode = () => {
    return typeof window !== 'undefined' ? (window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light') : 'light';
  }

  /**
   * Update mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeService
   */
  public updateMode(_mode: ThemeModeType) {
    const updatedMode = _mode === 'system' ? this.systemMode : _mode;
    this.mode.set(updatedMode);
  }

  /**
   * Switch mode
   * @param {ThemeModeType} _mode
   * @memberof ThemeModeService
   */
  public switchMode(_mode: ThemeModeType) {
    if (isPlatformBrowser(this.platformId)) {
      const updatedMode = _mode === 'system' ? this.systemMode : _mode;
      if (localStorage) {
        localStorage.setItem(THEME_MODE_LS_KEY, updatedMode);
      }
      this.document.location.reload();
    }
  }

  /**
   * Initialize theme mode
   * @memberof ThemeModeService
   */
  public init(): void {
    const currentTheme = getThemeModeFromLocalStorage(THEME_MODE_LS_KEY, this.platformId);
    this.updateMode(currentTheme);
  }
}
